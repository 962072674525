*{
  color:#000;
}
.App{
  overflow:hidden;
  height:100vh;
}
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #B9B9B9; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555;  */
background: #888; 
}